import React, { Fragment } from 'react';
import Layout from 'components/Layout';
import {
  AttachementsSlice,
  Features2x2,
  FlipCardSlice,
  ImageAccordionSlice,
  IntroSlice,
  LeadSignup,
} from 'components/Slices';
import { de, en } from '../intl';
import {
  firstImages,
  projectImages,
  aboutImages,
  securityIcons,
} from '../images';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import { addImagesToData } from '../helpers/helpers';

const createContentByLanguage = (lang) => {
  return {
    ...lang.pages.home,
    second: {
      ...lang.pages.home.second,
      items: addImagesToData(lang.pages.home.second.items, firstImages),
    },
    security: {
      ...lang.pages.home.security,
      items: addImagesToData(lang.pages.home.security.items, securityIcons),
    },
    projects: {
      ...lang.pages.home.projects,
      items: addImagesToData(lang.pages.home.projects.items, projectImages),
    },
    about: {
      ...lang.pages.home.about,
      items: addImagesToData(lang.pages.home.about.items, aboutImages),
    },
    downloads: {
      ...lang.pages.home.downloads,
      items: addImagesToData(lang.pages.home.downloads.items, securityIcons),
    },
  };
};

const content = {
  de: createContentByLanguage(de),
  en: createContentByLanguage(en),
};

export default () => {
  return (
    <Layout>
      <IntlContextConsumer>
        {({ language: currentLocale }) => {
          return (
            <Fragment>
              <IntroSlice data={content[currentLocale].intro} />
              <ImageAccordionSlice
                first
                sectionId="products"
                data={content[currentLocale].second}
              />
              <FlipCardSlice
                sectionId="projects"
                data={content[currentLocale].projects}
              />
              <ImageAccordionSlice
                sectionId="about"
                data={content[currentLocale].about}
              />
              <Features2x2 data={content[currentLocale].security} />
              <AttachementsSlice data={content[currentLocale].downloads} />
              <LeadSignup />
            </Fragment>
          );
        }}
      </IntlContextConsumer>
    </Layout>
  );
};
